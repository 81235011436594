export const skills = [
  'MVC Architecture',
  'Axios',
  'Fetch',
  'Material UI',
  'Styled Components',
  'Tailwind CSS',
  'Jest',
  'Nightwatch'
];
